export const calculateRentDays = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): number => {
  function isSunday(date: Date): boolean {
    return date.getDay() === 0; // 0 represents Sunday
  }

  function calculateDaysExcludingSundays(startDate: Date, endDate: Date): number {
    let days = 1;
    let currentDate = new Date(startDate);
    let firstSundayExcluded = false;

    while (currentDate < endDate) {
      if (!isSunday(currentDate) || firstSundayExcluded) {
        days++;
      }
      if (isSunday(currentDate) && !firstSundayExcluded) {
        firstSundayExcluded = true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  }

  const holidays: Date[] = [
    new Date(2024, 3, 27), // Koningsdag
    // Add more holidays as needed
  ];

  let days = calculateDaysExcludingSundays(startDate, endDate);
  holidays.forEach((holiday) => {
    if (holiday >= startDate && holiday <= endDate && !isSunday(holiday)) {
      days--;
    }
  });

  return days;
};
