'use client';

import { cn, useDebounce } from '@av/utils';
import { Minus, Plus } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const Counter = ({
  value,
  onChange,
  disabled,
  setQuantity,
  min = 0,
  step = 1,
  debounce = false,
  className,
}: {
  value: number;
  min?: number;
  step?: number;
  setQuantity?: number;
  /* eslint-disable no-unused-vars */
  onChange: (v: number) => void;
  disabled?: boolean;
  debounce?: boolean;
  className?: string;
}) => {
  const [inputValue, setInputValue] = useState<number | undefined>(value);
  const debouncedInputValue = useDebounce(inputValue, debounce ? 200 : 0);

  useEffect(() => {
    if (debouncedInputValue !== undefined) onChange(debouncedInputValue);
  }, [debouncedInputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const increment = () => {
    setInputValue((prev) => (prev ? prev + step : step));
  };

  const decrement = () => {
    if (inputValue && inputValue > (min || 0)) {
      setInputValue((prev) => Math.max(prev ? prev - step : min, min));
    } else {
      toast('Het minimale aantal is ' + min);
    }
  };

  return (
    <div className="group relative flex w-min items-center rounded border bg-[#e5e7eb]">
      {!!setQuantity && setQuantity >= 0 && (
        <div className="h-9 px-1.5">
          <button
            type="button"
            className="relative flex h-full w-min items-center whitespace-nowrap bg-[#e5e7eb] text-xs"
            aria-label={`Set quantity: ${setQuantity}`}
          >
            {setQuantity}x
          </button>
          <div className="absolute left-0 top-[calc(100%-2px)] w-full rounded-b bg-[#e5e7eb] p-2 text-center text-xs opacity-0 ring-1 ring-[#e5e7eb] transition-all group-hover:opacity-100">
            Losse items: <b>{setQuantity * (inputValue || 1)}</b>
          </div>
        </div>
      )}
      <div
        className={cn(
          'border-stroke dark:border-dark-3 relative z-1 text-dark inline-flex items-center rounded-l overflow-hidden text-base font-medium dark:text-white',
          className
        )}
      >
        <button
          type="button"
          className={cn(
            'text-dark flex h-9 w-[34px] min-w-[34px] cursor-pointer select-none items-center justify-center border-r bg-white hover:bg-gray-50 dark:text-white',
            !setQuantity && 'rounded-l'
          )}
          onClick={decrement}
          disabled={disabled}
          aria-label="Decrease quantity"
        >
          <Minus className="w-3" />
        </button>
        <label htmlFor="quantity-input" className="sr-only">
          Quantity
        </label>
        <input
          id="quantity-input"
          name="quantity"
          className={cn(
            'border-stroke dark:border-dark-3 text-center min-w-[34px] w-max max-w-[50px] h-9 bg-white text-center'
          )}
          value={inputValue}
          disabled={disabled}
          onBlur={() => {
            if (inputValue) {
              if (step) {
                if (inputValue < min) {
                  toast('Het minimale aantal is ' + min);
                  setInputValue(min);
                } else {
                  const value = inputValue;
                  const remainder = value % step;
                  if (remainder) toast('Je kunt dit artikel bestellen per ' + step);
                  setInputValue(value - (remainder ? remainder - step : remainder));
                }
              } else if (inputValue < min) {
                toast('Het minimale aantal is ' + min);
                setInputValue(min);
              }
            } else {
              toast('Het minimale aantal is ' + min);
              setInputValue(min);
            }
          }}
          type="numeric"
          min={min}
          step={step}
          onChange={(e) => {
            if (e.target.value) {
              const value = +e.target.value.replace(/[^0-9]/g, '');
              setInputValue(value);
            } else {
              setInputValue(undefined);
            }
          }}
          aria-label="Quantity"
        />
        <button
          type="button"
          className={cn(
            'text-dark flex h-9 w-[34px] min-w-[34px] cursor-pointer rounded-r select-none items-center justify-center border-l bg-white hover:bg-gray-50 dark:text-white'
          )}
          onClick={increment}
          disabled={disabled}
          aria-label="Increase quantity"
        >
          <Plus className="w-3" />
        </button>
      </div>
    </div>
  );
};

export default Counter;
