'use client';

import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink, loggerLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import SuperJSON from 'superjson';

import type { AppRouter } from '@av/api';
import { notifyError } from '@av/bugsnag';

export const api = createTRPCReact<AppRouter>();

export function TRPCReactProvider(props: {
  children: React.ReactNode;
  headersPromise: Promise<Headers>;
}) {
  const isDev = process.env.NODE_ENV === 'development';
  const [queryClient] = useState(() => new QueryClient());

  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        loggerLink({
          logger(op) {
            if (isDev) return console.info(op);
            notifyError(JSON.stringify(op));
          },
          enabled: (op) =>
            !process.env.DISABLE_TRPC_LOGGING &&
            (isDev || (op.direction === 'down' && op.result instanceof Error)),
        }),
        unstable_httpBatchStreamLink({
          transformer: SuperJSON,
          url: getBaseUrl() + '/api/trpc',
          async headers() {
            const headers = new Map(await props.headersPromise);
            headers.set('x-trpc-source', 'nextjs-react');

            return Object.fromEntries(headers);
          },
        }),
        httpLink({
          url: 'http://localhost:3000',
          transformer: SuperJSON,
        }),
      ],
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <api.Provider client={trpcClient} queryClient={queryClient}>
        {props.children}
      </api.Provider>
    </QueryClientProvider>
  );
}

function getBaseUrl() {
  if (typeof window !== 'undefined') return window.location.origin;
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;

  return `http://localhost:${process.env.PORT ?? 3000}`;
}
